@import './../../_variables.scss';

.navbar-container {
    $navbar-background-color-top: rgba(41, 41, 41, 0.5);
    $navbar-background-color-scroll: rgba(41, 41, 41, 0.5);
    $navbar-background-color-scroll: #323232;

    background-color: $navbar-background-color-top;
    height: $header-height-mobile;
    padding: 0.5rem 1rem;
    position: fixed !important;
    top: 0;
    width: 100vw;
    z-index: 999;

    .navbar {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-between;
        position: unset;
        width: 100%;

        &-brand {
            height: 100%;
            svg {
                height: 100%;
            }
        }

        &-toggler {
            align-items: center;
            border: none !important;
            display: flex !important;
            justify-content: flex-end;
            padding: 0 !important;
            height: 40px;
            width: 40px;
            position: relative;

            > span {
                background-image: none !important;
                background-color: #fff !important;
                border-radius: 4px;
                bottom: 13px !important;
                height: 3px !important;
                transition: all 0.4s ease-in-out;
                position: absolute !important;
                width: 100% !important;

                &::before {
                    transition: all 0.4s ease-in-out;
                    border-radius: 4px;
                    content: '';
                    position: absolute;
                    right: 0;
                    background-color: #fff !important;
                    height: 3px !important;
                    width: 40px !important;
                    transform: translateY(-10px);
                }
            }

            &.open {
                > span {
                    transform: rotate(45deg) translate(-3px, -4px);

                    &::before {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .nav-link {
            color: #fff !important;
            font-weight: 500;
        }

        &-collapse,
        .collapsing {
            background-color: $navbar-background-color-top;
            left: 0;
            padding: 0.5rem 1rem;
            position: absolute;
            top: $header-height-mobile;
            width: 100vw;
        }

        .dropdown {
            * {
                color: #fff;
            }

            &-header {
                font-weight: 700;
            }

            &-menu {
                background-color: unset;
                border: none;
            }

            &-divider {
                border-color: #ccc;
            }

            &-item {
                > a {
                    display: block;
                }

                &:hover,
                &:active {
                    background-color: #e9ecef;
                    > a {
                        color: #000;
                    }
                }
            }

            &-toggle {
                &::after {
                    display: none;
                }
            }
        }
    }

    &.scroll {
        background-color: $navbar-background-color-scroll;

        .navbar-collapse,
        .collapsing {
            background-color: $navbar-background-color-scroll;
        }
    }

    @media screen and (min-width: $breakpoint-mobile) {
        display: flex;
        justify-content: center;
        height: $header-height-desktop;
        padding: 0.7rem 1rem !important;
        width: 100%;
        
        .navbar {
            flex-grow: 1;
            max-width: $desktop-max_width;
            padding: 0;

            &-brand {

            }

            &-collapse,
            .collapsing {
                background-color: unset;
                justify-content: flex-end;
                position: unset;
                padding: 0;
            }

            .nav-item {
                padding: 0 0.5rem;

                .nav-link {
                    position: relative;
                    overflow-x: hidden;
                    padding: 0 !important;

                    &::before {
                        transition: transform 0.3s ease-in-out;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        content: '';
                        background-color: #fff;
                        bottom: 0;
                        left: 0;
                        transform: translateX(-110%);
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            transform: translateX(0%);
                        }
                    }
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            &-toggler {
                display: none !important;
            }

            .dropdown {
                &-menu {
                    margin: 0;
                    background-color: $navbar-background-color-top;
                    top: $header-height-desktop - 28px;
                    border-radius: 0 0 0.25rem 0.25rem;
                }

                &-divider {
                    margin-left: 1rem;
                    margin-right: 1rem;

                    &:first-child,
                    &:last-child {
                        display: none;
                    }
                }
            }

        }

        &.scroll {
            background-color: $navbar-background-color-scroll;

            .dropdown {
                &-menu {
                    background-color: $navbar-background-color-scroll;
                }
            }
        }
    }
}
