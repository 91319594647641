@import './variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    scroll-padding-top: $header-height-mobile;

    @media screen and (min-width: $breakpoint-mobile) {
        scroll-padding-top: $header-height-desktop;
    }
}

li {
    list-style: none;
}

a,
a:hover {
    text-decoration: none !important;
    color: unset;
}

#page-container {
    min-height: 100vh;
    overflow-x: hidden;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
